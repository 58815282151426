import { getWeb3 } from "../utils/onboard";
import getCookie from "../utils/getCookie";
import router from "../../router";
import Swal from "sweetalert2";

const web3Login = (name) => {
    getWeb3()
        .then((web3) => {
            return web3.eth.getAccounts().then((accounts) => {
                if (accounts.length == 0) {
                    this.props.onError("Web3 Error. Please try again.");
                    throw "Web3 error";
                } else {
                    return window.grecaptcha.ready(() => {
                        window.grecaptcha.execute(process.env.VUE_APP_reCAPTCHA_SITE_KEY, { action: "web3_login" }).then((recaptchaToken) => {
                            let msg = "rivet.cloud " + new Date().toISOString();
                            return web3.eth.personal.sign(msg, accounts[0]).then((signature) => {
                                return fetch("/api/login/web3", {
                                    method: "POST",
                                    body: JSON.stringify({
                                        recaptcha_token: recaptchaToken,
                                        msg: msg,
                                        signature: signature,
                                        account: accounts[0],
                                    }),
                                    headers: {
                                        "Content-Type": "application/json",
                                        "X-CSRFToken": getCookie("csrftoken"),
                                    },
                                })
                                    .then((response) => {
                                        return response.json();
                                    })
                                    .then((result) => {
                                        if (result.ok) {
                                            if (name !== "Login") {
                                                Swal.fire({
                                                    icon: "success",
                                                    titleText: `${name} Successful`,
                                                    text: `
                                            
                                            `,
                                                    confirmButtonColor: "#417dd8",
                                                    confirmButtonText: "Continue to Dashboard",
                                                });
                                            }
                                            router.push({ name: "overview" });
                                        }
                                    })
                                    .catch((err) => console.log(err));
                            });
                        });
                    });
                }
            });
        })
        .catch(console.log);
};

export default web3Login;
