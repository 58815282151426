import router from "@/router";
import store from "@/store";

const ifAuth = async () => {
    try {
        const res = await fetch("/api/account");
        if (res.ok) {
            router.push({ name: "overview" });
            store.state.authenticated = true;
        }
    } catch (err) {
        return console.log(err);
    }
};

export default ifAuth;
