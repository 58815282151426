<script setup>
import web3Login from "@/scripts/auth/web3Login.js";
import loadercomp from "@/components/loadercomp";
import { useRoute } from "vue-router";
import ifAuth from "@/scripts/auth/redirectIfAuth";
import router from "@/router";
import ErrorComp from "@/components/errormessage.vue";
import { ref } from "@vue/reactivity";
import getCookie from "@/scripts/utils/getCookie.js";
import { onBeforeMount, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
const store = useStore();
const email = ref(null);
const org = ref(null);
const route = useRoute();

onBeforeMount(() => {
    if (route.query.r) {
        if (typeof window != "undefined" && window) {
            window.localStorage.setItem("referral_code", route.query.r);
        }
    }
});

onMounted(() => {
    ifAuth();
});

const handleSubmit = () => {
    store.state.loader = true;
    window.grecaptcha.execute(process.env.VUE_APP_reCAPTCHA_SITE_KEY, { action: "signup" }).then(async (recaptchaToken) => {
        const response = await fetch("/api/account", {
            method: "POST",
            body: JSON.stringify({
                email: email.value,
                org: org.value,
                recaptcha_token: recaptchaToken,
                plan: "BUIDL",
                invite_code: route.query.i,
                referral_code: route.query.r,
            }),
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
        });
        const data = await response.json();
        store.state.loader = false;
        if (data.ok) {
            if (typeof window.dataLayer == "object") {
                window.dataLayer.push({ event: "signup-start" });
            }
            router.push({ path: "/buidl/signup/activate", query: { a: `${data.activation_id}` } });
        } else {
            switch (data.error) {
                case "bad-invite-code":
                    store.state.errMsg = "Your invite code is no longer valid";
                    break;

                case "duplicate-user":
                    store.state.errMsg = "This email address is already in use.";
                    break;
                default:
                    store.state.errMsg = "An unknown error has occurred, please reload page";
                    break;
            }
        }
    });
};
</script>
<template>
    <div>
        <div class="buidl_wrap flex flex_col">
            <loadercomp v-if="$store.state.loader" />
            <h2 class="ta_center">SIGN UP 1/1</h2>
            <p class="ta_center">
                Welcome to Rivet! Sign-up quickly using your email address or Web3 wallet. To sign-up with an email address, we'll send you a
                verification code to confirm your new account.
            </p>
            <form class="rivet_signup auth_form flex flex_col" @submit.prevent="handleSubmit">
                <ErrorComp :errMsg="$store.state.errMsg" />
                <div class="input_data">
                    <div class="name_project">
                        <input required type="text" v-model="org" placeholder="Organization / Project name" />
                    </div>
                    <div id="email rivet_login_email">
                        <input required v-model="email" type="email" autocomplete="email" placeholder="Email" />
                    </div>
                    <div class="btn_web3_from_prompt">
                        <button type="button" @click.prevent="web3Login('Signup')">
                            Signup with Web3
                            <img width="15" src="@/assets/ethe_.svg" alt="" />
                        </button>
                    </div>
                </div>
                <div class="btn btn_color ta_center">
                    <button type="submit">Submit</button>
                </div>
            </form>
            <div class="haveAccount ta_center">
                <p>
                    Already have an account?
                    <router-link class="routing" :to="{ name: 'login' }">Login</router-link>
                </p>
            </div>
        </div>
        <div class="background_items">
            <div class="img1 img">
                <img src="https://res.cloudinary.com/ferventdev/image/upload/v1655148340/rivet.cloud/eth_3d_ctfa27.png" alt="" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/style/auth/signup.scss";
</style>
